@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/thin";

html {
	background-color: $misty-rose;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

.btn {
	border: 1px solid $mauve;
	color: $mauve;
	background-color: $misty-rose;
	border-radius: rems(24);
	font-size: rems(16);
}

.btn-light {
	border: none;
	color: $misty-rose;
	background-color: $nobel;
}

// CAROUSEL
.owl-custom-nav-prev,
.owl-custom-nav-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: rem(53);
}

.owl-custom-nav-prev {
	left: 0;
}

.owl-custom-nav-next {
	right: 0;
}

header {
	display: flex;
	flex-direction: column;
	align-items: center;

	.container {

		@include tablet-down {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.mobile-logo {
			max-width: rems(220);
			width: 100%;

			@include tablet-up {
				display: none
			}
		}

		.mobile-menu-icon {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			pointer-events: all;
			cursor: pointer;
			position: relative;
			margin: rems(8) 0;

			// ICON SIZE
			width: 35px;
			height: 40px;

			@include tablet-up {
				display: none
			}

			.line {
				width: 100%;
				height: 2px;
				background-color: $black;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}

		.navbar {
			display: flex;
			justify-content: space-between;

			@include tablet-down {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				flex-direction: column-reverse;
				justify-content: center;
				align-items: center;
				background-color: $double-spanish-white;
				padding: rems(50);
				border: 1px solid $black;
				border-right: none;
				border-left: none;
				transition: top .3s ease-in-out;


				&:not(.active) {
					top: -800px;
				}
			}

			ul.social {
				display: flex;
				list-style: none;
				gap: rems(10);

				@include tablet-down {
					padding: 0;
				}

				li {
					display: flex;
					align-items: center;
				}
			}

			h1 {
				img {
					max-width: rems(220);
					width: 100%;
				}

				@include tablet-down {
					display: none;
				}
			}

			nav {
				display: flex;
				align-items: center;

				ul {
					display: flex;
					list-style: none;
					gap: rems(20);
					color: $black;
					font-weight: 300;

					@include tablet-down {
						flex-direction: column;
						align-items: center;
						padding: 0;
						gap: rems(40);
						font-size: rems(44);
					}

					li {
						a.scroll {}

						a.btn {
							padding: rems(8) rems(15);

							@include tablet-down {
								font-size: rems(22);
							}
						}
					}
				}
			}
		}
	}

	img#hero {
		width: 100%;
		height: auto;
		min-width: rems(700);

	}
}


main {

	section#transition {
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;

		.container {
			display: flex;
			justify-content: center;
			padding: rems(24);
		}
	}

	section#tour {
		background-image: url("../img/bg/gw-bg.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: rems(50) 0;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			#shows {
				padding: rems(12) rems(50);
				font-size: rems(40);
				border-radius: rems(100);
				font-weight: 300;
				background-color: $mauve;
				color: $misty-rose;
				border: 1px solid $mauve;

				@include hover-focus {
					background-color: $misty-rose;
					color: $mauve;
				}
			}



			.tour-wrapper {
				background-color: $misty-rose;
				color: $black;
				min-height: rems(300);
				border: 1px solid $black;
				padding: rems(10) rems(40) rems(40);
				margin-bottom: rems(20);

				#events {
					.event-wrapper {
						display: flex;
						align-items: center;
						color: #000;
						padding-bottom: rems(16);
						margin-bottom: rems(16);
						border-bottom: 1px solid $reef-gold-50;

						@include tablet-down {
							flex-direction: column;
							gap: rems(8);
						}

						.event-date {
							flex: 1;
							font-size: 20px;
						}

						.event-location {
							flex: 1;
							font-size: 16px;
						}

						.event-venue {
							flex: 1;
							font-size: 16px;
						}

						.event-links {
							flex: 1;
							display: flex;
							justify-content: flex-end;

							a.btn {
								font-size: 11px;
								padding: rems(5) rems(16);
							}
						}
					}

					.less {
						&:nth-child(n+7) {
							display: none;
						}
					}

					.no-events {
						text-align: center;
					}
				}
			}

			.btn-wrapper {
				margin: rems(50) auto;
				text-align: center;

				.btn {
					font-size: rems(22);
					font-weight: 300;
					padding: rems(5) rems(16);
				}
			}
		}
	}

	section#music {
		padding: rems(100) 0;
		border-top: 1px solid $black;
		border-bottom: 1px solid $black;

		.container {
			.owl-carousel {
				display: flex;
				// align-items: center;
				justify-content: center;

				.item {
					display: flex;
					justify-content: center;

					@include tablet-down {
						flex-direction: column;
						align-items: center;
						padding: 0 60px;
					}

					.cover {
						width: 100%;
						max-width: rems(362);
						max-height: rems(350);

						@include tablet-down {
							margin-bottom: rems(32);
						}

						img {
							width: 100%;
						}
					}

					.info {
						@include flex(column, center, flex-start);
						padding: 0 rems(50);

						@include tablet-down {
							display: flex;
							align-items: center;
							flex-direction: column;
						}

						p.category {
							font-style: italic;
							color: $mauve;
							font-weight: 300;
							font-size: rems(16);


						}

						p.title {
							color: $black;
							font-weight: 300;
							font-size: rems(24);

							@include tablet-down {
								text-align: center;
							}
						}

						a.btn {
							font-weight: 300;
							font-size: rems(14);
							display: flex;
							align-items: center;
							justify-content: center;
							padding: rems(5) rems(12);
							color: $black;

							img {
								width: 15px;
								height: 15px;
								margin-right: 10px;
							}
						}

					}
				}
			}
		}
	}

	section#merch {
		background-image: url("../img/bg/gw-bg.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		margin: 0;
		padding-bottom: rems(80);
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 50%;
			background-color: $double-spanish-white;
			border-top: 1px solid $black;

			@media screen and (max-width: 690px) {
				display: none;
			}

		}


		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			z-index: 3;


			// TERMPORARY
			// height: rems(90);

			.vertical-line {
				height: rems(80);
				width: 1px;
				border-right: 1px solid $black;
				margin: rems(20) 0;
			}

			h2 {
				color: $black;
				font-weight: 300;
				font-size: rems(32);
				font-style: italic;
				letter-spacing: .63px;
				margin: 0 0 rems(48);
				text-align: center;
			}

			.wrapper {
				display: flex;
				justify-content: center;

				@media screen and (max-width: 690px) {
					flex-direction: column;
				}

				.featured-product {
					background-color: $misty-rose;
					border: 1px solid $black;
					flex: .30;
					gap: rems(68);
					min-width: rems(450);

					@media screen and (max-width: 690px) {
						max-width: rems(400);
						margin-bottom: rems(50);
					}

					.img-wrapper {
						border-bottom: 1px solid $black;
						display: flex;
						justify-content: center;
						padding: rems(50) 0
					}

					.info {
						@include flex(column, center, center);
						padding: rems(37);

						p {
							color: $black;
							font-weight: 300;
							font-size: rems(22);
							letter-spacing: .44px;
						}

						a.btn {
							padding: rems(10) rems(20);
						}
					}
				}
			}
		}
	}
}

section#videos {
	padding: rems(50) 0 rems(80);
	border-top: 1px solid $black;
	border-bottom: 1px solid $black;

	.container {

		.owl-carousel {
			display: flex;
			align-items: center;
			justify-content: center;

			.item {
				padding: 0 rems(100);
			}
		}
	}
}

section#contact {
	background-image: url("../img/bg/gw-bg.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: rems(50) 0;

	.container {
		@include flex(column, center, center);

		h2 {
			color: $black;
			font-weight: 300;
			font-size: rems(32);
			font-style: italic;
			letter-spacing: .63px;
			margin: 0;
		}

		form {
			width: 100%;
			max-width: rems(715);
			margin: rems(20) 0;
			display: flex;
			justify-content: center;

			@media screen and (max-width: 600px) {
				flex-direction: column;
				align-items: center;
				gap: rems(16);
			}

			input[type="email"] {
				padding: rems(13);
				width: 100%;
				max-width: rems(435);
				margin-right: rems(16);

				&::placeholder {
					color: lighten($black, 50%);
					font-size: rems(18);
					font-weight: 300;
				}
			}

			button.btn {
				font-size: rems(18);
				padding: rems(13) rems(16);
				font-weight: 300;

				i {
					margin-left: rems(8);
				}
			}
		}

		p {
			font-style: italic;
			color: $mauve;
			font-weight: 300;
			font-size: rems(16);

			img {
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
			}
		}
	}
}

footer {
	color: $black;
	text-align: center;
	font-size: rems(12);
	font-weight: 300;
	padding: rems(88);
	border-top: 1px solid $black;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: rems(100);

		@media screen and (max-width: 690px) {
			flex-direction: column;
			gap: rems(28);
		}

		.logo-wrapper {
			width: 100%;
			max-width: rems(254);
		}

		.copy-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
			max-width: rems(533);

			ul.social {
				display: flex;
				list-style: none;
				gap: rems(10);
				padding: 0;
				margin: 0 0 rems(40);

				li {
					display: flex;
					align-items: center;
				}
			}

			.copyright {
				p {
					font-family: $copy-font;
					letter-spacing: rems(3);
					font-size: rems(12);
					width: 100%;
					text-align: left;
					line-height: 1.1em;

					@media screen and (max-width: 690px) {
						text-align: center;
					}
				}
			}
		}
	}
}


.header-wrapper {
	display: flex;
	align-items: center;
	color: $black;
	margin-bottom: rems(50);

	h2 {
		font-size: rems(32);
		font-weight: 300;
		font-style: italic;
		margin: 0;
	}

	.decal {
		flex: 1;
		height: 2px;
		background-color: $reef-gold;
		margin-left: rems(20);
	}
}