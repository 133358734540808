$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$mauve: #5B274A;
$misty-rose: #EEEAE1;
$double-spanish-white: #DDD8C0;
$nobel: #bea9b1;
$reef-gold: #8E8829;
$reef-gold-50: #8E882980;

@font-face {
  font-family: 'Karla';
  src: url('../font/Karla-Regular.woff2') format('woff2'),
      url('../font/Karla-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


$primary-font: Helvetica Neue, Helvetica, Arial, sans-serif;
$copy-font: Karla;
